
  import {Component, Ref, Vue} from "vue-property-decorator";
  import CustomFormDataTableDetail from "@/components/CustomForm/TableDetail/index.vue";
  import {
    AttachmentDto,
    AttachmentHostType,
    AuditFlowScope,
    CustomFormType, ExternalProjectDetailDto, InternalProjectDetailDto, InternalProjectDto,
    ProjectSpecificGoalDto
  } from "@/api/appService";
  import api from '@/api/index';
  import {AuditBodyDto} from "@/utils/customDto";
  import ExportWord from "@/components/DownloadWord/index.vue";
  import AttachmentsView from "@/components/AttachmentsView/index.vue";
  import AuditButtons from "@/components/AuditButtons/index.vue";

  @Component({
    name:'CustomFormStandardDetail',
    components: {
      CustomFormDataTableDetail,
      ExportWord,
      AttachmentsView,
      AuditButtons
    },
    filters:{
      formatSpecificGoal(goalId:number|string,effects:ProjectSpecificGoalDto[]){
        let specificGoal:string|undefined
        if(effects&&effects.length>0) {
          effects.map((item:ProjectSpecificGoalDto)=>{
            if(item.id == goalId) {
              specificGoal = item.effect
              return
            }
          })
        }
        return specificGoal
      },
      formatBudgetCategory(budgetCategory:string,projectBudgetCategoryList:any[]) {
        let budget:string|undefined
        if(projectBudgetCategoryList&&projectBudgetCategoryList.length>0) {
          projectBudgetCategoryList.map((item:any)=>{
            if(item.value == budgetCategory) {
              budget = item.text
              return
            }
          })
        }
        return budget
      }
    }
  })
  export default class CustomFormStandardDetail extends Vue{
    @Ref() auditButtons!: AuditButtons;
    private dataId!:string;
    private hostType!:CustomFormType;
    private attachmentHostType:AttachmentHostType = AttachmentHostType.Internal_Project;
    private isProject = false;
    isReady = false;

    styles:string="";
    selector:string="detail-wrap"
    filename:string="项目立项书";
    auditParams: AuditBodyDto = {
      hostId: undefined,
      hostType: AuditFlowScope.InternalProject,
      auditFlowId: undefined,
      auditStatus: undefined,
      audit: undefined
    }

    detail: InternalProjectDetailDto = {};
    // 项目预算种类 一级
    projectBudgetCategoryList = [];

    // 项目预算种类 二级
    projectBudgetTypeList = [];

    // 资金来源
    projectBudgetCapitalSourceList = [];

    created() {
      this.fetchEnum();
      this.dataId = this.$route.query.id as string;
      this.hostType = this.$route.query.hostType as CustomFormType;
      this.isProject = true;

      if(this.dataId && this.hostType){
        this.isReady = true;
      } else {
        this.$message.error('缺少url参数 id 或 hostType，请检查!');
      }

      this.fetchAuditDetail()
    }

    fetchAuditDetail() {
      api.internalProject.getDetailByProjectId({projectId: parseInt(this.dataId)}).then(res => {
        this.detail = {...res};
        this.auditParams!.hostId = res.id +''
        this.auditParams!.auditFlowId = res.auditFlowId;
        this.auditParams!.auditStatus = res.auditStatus;
        this.auditParams!.audit = res.audit;
        if(res.auditFlowId) {
          (this.auditButtons as any).fetchCanAudit()
        }
      })
    }

    getData(){
      return api.internalProject.getDetailByProjectId({projectId:parseInt(this.dataId)});
    }

    fetchEnum() {
      api.enumService.getValues({typeName: 'ProjectBudgetCategory'}).then((res:any) => {
        this.projectBudgetCategoryList = res
      });
      api.enumService.getValues({typeName: 'ProjectBudgetType'}).then((res:any) => {
        this.projectBudgetTypeList = res
      });
      api.enumService.getValues({typeName: 'ProjectBudgetCapitalSource'}).then((res:any) => {
        this.$nextTick(()=>{
          this.projectBudgetCapitalSourceList = res
        })
      });
    }

    // 返回
    private cancel() {
      this.$router.back();
    }

    fetchData() {
      return api.internalProject.audit;
    }

    changeAudit() {
      this.fetchAuditDetail()
    }
  }
