
import {Component, Vue} from "vue-property-decorator";
import api from "@/api/index";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import moment from "moment";
import {
  AuditFlowScope,
  CustomFormType,
  InternalProjectDto,
  InternalBudgetItemAndUseRecordDto,
} from "@/api/appService";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";
import AuditFlowDetailButton from "@/components/AuditFlowDetailButton/index.vue";
import InternalProjectOrgMapping from "@/views/project/internalProject/orgMapping/index.vue";
import ProjectUploadTitleImage from "@/views/project/common/UploadTitleImage.vue";
import ProjectPublishButton from "@/views/project/common/ProjectPublishButton.vue";
import BudgetItemsEdit from "@/views/project/common/InternalBudgetItemsEdit.vue";
import ProjectTypeEdit from "@/views/project/common/ProjectTypeEdit.vue";
import ProjectManagerEdit from "@/views/project/common/ProjectManagerEdit.vue";
import BudgetItemSelect from "@/views/finance/expenseRecord/selectBudgetItem.vue";

@Component({
  name: "internalProjectProposalList",
  components: {
    ProjectManagerEdit,
    ProjectUploadTitleImage,
    InternalProjectOrgMapping,
    PagedTableView,
    AbSelect,
    AgileAuditTag,
    AuditFlowDetailButton,
    ProjectPublishButton,
    BudgetItemsEdit,
    ProjectTypeEdit,
    BudgetItemSelect,
  },
})
export default class InternalProjectProposalList extends Vue {
  queryForm: any = {
    displayName: undefined,
    year: undefined,
  };
  currentProjectId = 0;
  itemRecord: InternalBudgetItemAndUseRecordDto = {};

  created() {
    if (this.$route.query.projectId) {
      this.queryForm.projectId = Number(this.$route.query.projectId);
    }
  }

  fetchData(params: any) {
    return api.internalProject.getAll(params);
  }

  // 操作按钮
  handleMenu($row: any) {
    this.currentProjectId = $row.item.project.id;
    switch ($row.type) {
      case "detail":
        this.handleDetail($row.item);
        break;
      case "edit":
        this.handleEdit($row.index, $row.item);
        break;
      case "changeEdit":
        this.handleChangeEdit($row.index, $row.item);
        break;
      case "delete":
        this.handleDelete($row.index, $row.item);
        break;
      case "startAudit":
        this.handleStartAudit($row.index, $row.item);
        break;
      case "auditFlow":
        this.handleAuditFlow($row.index, $row.item);
        break;
      case "beneficiary":
        this.handleBeneficiary($row.index, $row.item);
        break;
      case "mapping":
        (this.$refs.internalProjectOrgMapping as any).show = true;
        break;
      case "titleImage":
        (this.$refs.projectUploadTitleImage as any).show = true;
        break;
      case "updateBudgetItems":
        (this.$refs.budgetItemsEdit as any).show = true;
        break;
      case "publishControl":
        api.project
          .changePublicShow({projectId: this.currentProjectId})
          .then(() => {
            this.$message.success("是否公示已更新");
          });
        break;
      case "updateProjectType":
        (this.$refs.projectTypeEdit as any).show = true;
        break;

      case "updateProjectManager":
        (this.$refs.projectManagerEdit as any).show = true;
        break;
      case "editProjectResult":
        this.$router.push({
          name: "editProjectResult",
          params: {
            projectId: this.currentProjectId.toString(),
          },
        });
        break;
      case "pay":
        this.itemRecord.projectId = this.currentProjectId;
        (this.$refs.editForm as any).show = true;
        (this.$refs.editForm as any).current = this.itemRecord;
        break;
    }
  }

  handleCreate() {
    this.$router.push({
      name: "monthlyPlan-create",
    });
  }

  handleEdit(index: number, row: any) {
    api.customFormService
      .getCustomFormId({
        hostType: CustomFormType.InternalProject,
        hostId: String(row.projectId),
      })
      .then((res) => {
        if (!res) {
          this.$message.warning("暂无内部项目自定义表单!");
          // this.$confirm('暂无内部项目自定义表单，是否前往创建?', '提示', {
          //   confirmButtonText: '确定',
          //   cancelButtonText: '取消',
          //   type: 'warning'
          // }).then(() => {
          //   this.$router.push({
          //     name: 'projectCustomForm',
          //     query: {
          //       id: String(row.projectId,)
          //     }
          //   })
          // });
        } else {
          this.$router.push({
            name: "internalProjectProposalEdit",
            query: {
              id: row.id,
              projectId: row.project.id,
            },
          });
        }
      });
  }

  handleChangeEdit(index: number, row: any) {
    api.customFormService
      .getCustomFormId({
        hostType: CustomFormType.InternalProject,
        hostId: String(row.projectId),
      })
      .then((res) => {
        if (!res) {
          this.$message.warning("暂无内部项目自定义表单!");
        } else {
          this.$router.push({
            name: "internalProjectProposalEdit",
            query: {
              id: row.id,
              projectId: row.project.id,
              isChangeEdit: 'true'
            },
          });
        }
      });
  }

  handleDetail(row: any) {
    api.customFormService
      .getCustomFormId({
        hostType: CustomFormType.InternalProject,
        hostId: String(row.projectId),
      })
      .then((res) => {
        if (!res) {
          this.$message.warning("暂无内部项目自定义表单!");
          // this.$confirm('暂无内部项目自定义表单，是否前往创建?', '提示', {
          //   confirmButtonText: '确定',
          //   cancelButtonText: '取消',
          //   type: 'warning'
          // }).then(() => {
          //   this.$router.push({
          //     name: 'projectCustomForm',
          //     query: {
          //       id: String(row.projectId,)
          //     }
          //   })
          // });
        } else {
          this.$router.push({
            name: "internalProjectProposalDetail",
            query: {
              id: row.project.id,
              hostType: CustomFormType.InternalProject,
            },
          });
        }
      });
  }

  handleAuditFlow(index: number, row: any) {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.InternalProject,
        hostId: row.id,
        auditFlowId: row.auditFlowId,
      },
    });
  }

  //  提交审核
  async handleStartAudit(index: number, row: InternalProjectDto) {
    await api.internalProject
      .startAudit({body: {id: row.id}})
      .then(() => {
        this.$message.success("已提交");
      });
  }

  //获取审核状态
  getAuditStatus(index: number, row: any) {
    if ((row.audit != null && row.audit >= 0) && row.auditStatus === row.audit) {//已审核
      return true;
    } else if (row.audit === -1) {//被拒绝
      return false;
    } else if (row.audit == null) {//未开始
      return false;
    } else {//审核中
      return true;
    }
  }

  // 删除
  async handleDelete(index: number, row: InternalProjectDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.internalProject.delete({id: row.id}).then(() => {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      });
    });
  }

  // 项目受益人管理
  handleBeneficiary(index: number, row: InternalProjectDto) {
    api.customFormService
      .getCustomFormId({
        hostType: CustomFormType.Beneficiary,
        hostId: String(row.projectId),
      })
      .then((res) => {
        if (!res) {
          this.$message.warning("暂无内部项目受益人自定义表单!");
          // this.$confirm('暂无内部项目受益人自定义表单，是否前往创建?', '提示', {
          //   confirmButtonText: '确定',
          //   cancelButtonText: '取消',
          //   type: 'warning'
          // }).then(() => {
          //   this.$router.push({
          //     name: 'projectCustomForm',
          //     query: {
          //       id: String(row.projectId,)
          //     }
          //   })
          // });
        } else {
          this.$router.push({
            name: "internalProjectProposalBeneficiaryList",
            query: {
              id: String(row.id),
              formId: String(res),
              projectId: String(row.projectId),
            },
          });
        }
      });
  }
}
