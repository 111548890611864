
import {Component, Vue} from "vue-property-decorator";
import api from "@/api";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";
import ProjectPublishButton from "@/views/project/common/ProjectPublishButton.vue";
import ProjectUploadTitleImage from "@/views/project/common/UploadTitleImage.vue";
import ProjectTypeEdit from "@/views/project/common/ProjectTypeEdit.vue";
import ProjectManagerEdit from "@/views/project/common/ProjectManagerEdit.vue";
import BudgetItemSelect from "@/views/finance/expenseRecord/selectBudgetItem.vue";
import {
  CustomFormType,
  ExternalProjectDto, InternalBudgetItemAndUseRecordDto,
  InternalProjectDto,
  ServiceRecordType
} from "@/api/appService";

@Component({
  name: "internalProjectPhasesList",
  components: {
    PagedTableView,
    AgileAuditTag,
    ProjectPublishButton,
    ProjectUploadTitleImage,
    ProjectTypeEdit,
    ProjectManagerEdit,
    BudgetItemSelect
  },
  filters: {
    formatWorkflowStep(status: string, list: any[]) {
      let result = '';
      if (list && list.length > 0) {
        list.map((item) => {
          if (item.value == status) {
            result = item.text;
            return;
          }
        });
      }
      return result;
    }
  }
})

export default class InternalProjectPhasesList extends Vue {
  queryForm = {
    displayName: undefined,
    year: undefined,
    //isPhase: true
  }
  currentProjectId = 0;
  itemRecord: InternalBudgetItemAndUseRecordDto = {};
  workflowStepList: any = [];

  created() {
    this.fetchEnum();
  }

  fetchEnum() {
    api.enumService.getValues({typeName: 'WorkflowOfProjectByInternal'})
      .then(res => {
        this.workflowStepList = res;
      })
  }

  fetchData(params: any) {
    //params.isPhase = true;
    return api.internalProject.getAll(params);
  }

  // 操作按钮
  handleMenu($row: any) {
    this.currentProjectId = $row.item.project.id;
    switch ($row.type) {
      case "updateProjectType":
        (this.$refs.projectTypeEdit as any).show = true;
        break;
      case "updateProjectManager":
        (this.$refs.projectManagerEdit as any).show = true;
        break;
      case "beneficiary":
        this.handleBeneficiary($row.index, $row.item);
        break;
      case "handleBeneficiaryService":
        this.handleBeneficiaryService($row.index, $row.item);
        break;
      case "handleOrgService":
        this.handleOrgService($row.index, $row.item);
        break;
      case "pay":
        this.itemRecord.projectId = this.currentProjectId;
        (this.$refs.editForm as any).show = true;
        (this.$refs.editForm as any).current = this.itemRecord;
        break;
      case "phasesDetail":
        this.checkProjectPhases($row.index, $row.item);
        break;
      case "phasesStop":
        this.handleStop($row.index, $row.item);
        break;
    }
  }

  // 项目受益人管理
  handleBeneficiary(index: number, row: InternalProjectDto) {
    api.customFormService
      .getCustomFormId({
        hostType: CustomFormType.Beneficiary,
        hostId: String(row.projectId),
      })
      .then((res) => {
        if (!res) {
          this.$message.warning("暂无内部项目受益人自定义表单!");
          // this.$confirm('暂无内部项目受益人自定义表单，是否前往创建?', '提示', {
          //   confirmButtonText: '确定',
          //   cancelButtonText: '取消',
          //   type: 'warning'
          // }).then(() => {
          //   this.$router.push({
          //     name: 'projectCustomForm',
          //     query: {
          //       id: String(row.projectId,)
          //     }
          //   })
          // });
        } else {
          this.$router.push({
            name: "internalProjectProposalBeneficiaryList",
            query: {
              id: String(row.id),
              formId: String(res),
              projectId: String(row.projectId),
            },
          });
        }
      });
  }

  handleBeneficiaryService(index: number, row: InternalProjectDto) {
    const hostType = CustomFormType.BeneficiaryServiceRecord;
    api.customFormService.getCustomFormId({hostType: hostType, hostId: String(row.projectId)}).then(res => {
      if (!res) {
        this.$message.warning('暂无内部项目受益人服务记录自定义表单!');
        // this.$confirm('暂无内部项目'+ this.serviceRecordType +'服务记录自定义表单，是否前往创建?', '提示', {
        //   confirmButtonText: '确定',
        //   cancelButtonText: '取消',
        //   type: 'warning'
        // }).then(() => {
        //   this.$router.push({
        //     name: 'projectCustomForm',
        //     query: {
        //       id: String(row.projectId,)
        //     }
        //   })
        // });
      } else {
        this.$router.push({
          name: 'internalProjectBeneficiaryServiceRecordList',
          query: {
            projectId: String(row.projectId),
            type: ServiceRecordType.Beneficiary,
            formId: res,
            id: String(row.id)
          }
        })
      }
    })
  }

  handleOrgService(index: number, row: InternalProjectDto) {
    const hostType = CustomFormType.SchoolServiceRecord;
    api.customFormService.getCustomFormId({hostType: hostType, hostId: String(row.projectId)}).then(res => {
      if (!res) {
        this.$message.warning('暂无内部项目组织服务记录自定义表单!');
        // this.$confirm('暂无内部项目'+ this.serviceRecordType +'服务记录自定义表单，是否前往创建?', '提示', {
        //   confirmButtonText: '确定',
        //   cancelButtonText: '取消',
        //   type: 'warning'
        // }).then(() => {
        //   this.$router.push({
        //     name: 'projectCustomForm',
        //     query: {
        //       id: String(row.projectId,)
        //     }
        //   })
        // });
      } else {
        this.$router.push({
          name: 'internalProjectBeneficiaryServiceRecordList',
          query: {
            projectId: String(row.projectId),
            type: ServiceRecordType.School,
            formId: res,
            id: String(row.id)
          }
        })
      }
    })
  }

  // 查看项目周期
  checkProjectPhases(index: number, row: InternalProjectDto) {
    this.$router.push({
      name: 'internalProjectPhasesIndex',
      params: {
        id: String(row.projectId)
      }
    })
  }

  handleStop(index: number, row: ExternalProjectDto) {
    this.$confirm('确定终止该项目吗', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      api.project.terminate({projectId: row.projectId})
        .then(() => {
          this.$message({
            type: "success",
            message: "修改成功!"
          });
        })
    });
  }
}
