
  import {Component, Prop, Vue, Watch} from "vue-property-decorator";
  import api from '@/api/index';
  import {OrganizationUnitDto} from "@/api/appService";

  @Component({name:'InternalProjectOrgMapping'})
  export default class InternalProjectOrgMapping extends Vue{


    @Prop({required:true,default:0})
    projectId!:number ;

    show:boolean = false;
    submitting = false;


    private selectedOrganizationUnits:number[] = [];
    private allOrganizationUnits:OrganizationUnitDto[] = [];

    async created(){


      await this.loadOrganizationUnits();

    }

    async loadOrganizationUnits(){
      await api.organizationUnit.getAll({maxResultCount:65535}).then(res=>{
        this.allOrganizationUnits = res.items!;
      });
    }

    async loadMapping(){
      await api.project.getProjectMappings({projectId:this.projectId}).then(res=>{
        this.selectedOrganizationUnits = res.organizationUnitIds??[];
      });
    }

    @Watch('projectId')
    onProjectIdChange(newProjectId:number){
      this.loadMapping();
    }

    handleSubmit(){
      this.submitting = true;
      api.project.updateMapping({body:{projectId:this.projectId,organizationUnitIds:this.selectedOrganizationUnits}}).then(res=>{
        this.$message.success('更新成功');
        this.submitting = false;
        this.show = false;
      }).catch(res=>{
        this.submitting = false;
      })
    }




  }
