
import {Component, Vue} from "vue-property-decorator";
import api from "@/api";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";
import ProjectPublishButton from "@/views/project/common/ProjectPublishButton.vue";
import {ExternalProjectDto, InternalProjectDto} from "@/api/appService";
import ProjectUploadTitleImage from "@/views/project/common/UploadTitleImage.vue";

@Component({
  name: "internalProjectPhasesList",
  components: {
    PagedTableView,
    AgileAuditTag,
    ProjectPublishButton,
    ProjectUploadTitleImage
  },
  filters: {
    formatWorkflowStep(status: string, list: any[]) {
      let result = '';
      if (list && list.length > 0) {
        list.map((item) => {
          if (item.value == status) {
            result = item.text;
            return;
          }
        });
      }
      return result;
    }
  }
})

export default class InternalProjectPhasesList extends Vue {
  queryForm = {
    displayName: undefined,
    year: undefined,
    isPhase: true
  }
  currentProjectId = 0;
  workflowStepList: any = [];

  created() {
    this.fetchEnum();
  }

  fetchEnum() {
    api.enumService.getValues({typeName: 'WorkflowOfProjectByInternal'})
      .then(res => {
        this.workflowStepList = res;
      })
  }

  fetchData(params: any) {
    params.isPhase = true;
    return api.internalProject.getAll(params);
  }

  // 操作按钮
  handleMenu($row: any) {
    this.currentProjectId = $row.item.project.id;
    switch ($row.type) {
      case "editProjectResult":
        this.$router.push({
          name: "editProjectResult",
          params: {
            projectId: this.currentProjectId.toString(),
          },
        });
        break;
      case "titleImage":
        (this.$refs.projectUploadTitleImage as any).show = true;
        break;
      case "publishControl":
        api.project
          .changePublicShow({projectId: this.currentProjectId})
          .then(() => {
            this.$message.success("是否公示已更新");
          });
        break;
    }
  }

  // 查看项目周期
  checkProjectPhases(row: InternalProjectDto) {
    this.$router.push({
      name: 'internalProjectPhasesIndex',
      params: {
        id: String(row.projectId)
      }
    })
  }

  handleStop(row: ExternalProjectDto) {
    this.$confirm('确定终止该项目吗', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      api.project.terminate({projectId: row.projectId})
        .then(() => {
          this.$message({
            type: "success",
            message: "修改成功!"
          });
        })
    });
  }
}
